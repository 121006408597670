<template>
  <div>
    <page-header v-if="hasHeader" :title="pageTitle">
      <slot name="rightButton"></slot>
    </page-header>
    <div class="page-container" :class="{ 'page-unloaded': !isPageLoaded }" :style="pageStyle">
      <slot />
    </div>
    <loading-bar v-if="!isPageLoaded" />
  </div>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader.vue";
import LoadingBar from "@/components/LoadingBar.vue";

export default {
  name: "DetailContainer",
  components: {
    PageHeader,
    LoadingBar
  },
  props: {
    pageTitle: {
      type: String,
      default: ""
    },
    pageStyle: {
      type: Object,
      default: () => ({})
    },
    isPageLoaded: {
      type: Boolean,
      default: true
    },
    hasHeader: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background-color: #f5f6f7;
  transition: opacity 0.1s;
  opacity: 1;
  height: 100vh;

  &.page-unloaded {
    opacity: 0;
  }

  &.has-fixed-header {
    padding-top: 46px;
  }
}

.page-info {
  padding: 46px 20px;
  background-color: #f5f6f7;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #666;
  opacity: 0.8;

  &-list {
    list-style: none;
    margin: 0;
    padding: 0 0 0 12px;

    li {
      margin: 5px 0 0;

      &:first-child {
        margin-top: 0;
      }

      &:before {
        display: inline-block;
        margin: 0 5px 0 -12px;
        content: "\00B7";
      }
    }
  }
}
</style>
