<template>
  <div class="page-header">
    <button class="page-header-back-button" @click="onClickBackButton"><i class="icon icon-arrow-left" /></button>
    <div class="page-header-title">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/components/_page-header";
</style>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  methods: {
    onClickBackButton() {
      this.$router.go(-1);
    }
  }
};
</script>
